<template>
<div id="GalleryList">

<section id="Gallery" class="deep-purple darken-1">
<v-container>
<div class="titleDiv px-5">
  <p class="display-1">Gallery</p>
</div>
<div>

<v-row v-show="GalleryData">
<v-col cols="12" sm="12" md="4" class="my-5" v-for="gallery in GalleryData" :key="gallery.imageUrl" height="100%">
  <v-card class="mx-auto image-card pa-0 grey lighten-2 elevation-5 animated fadeIn slower" height="100%">

    <v-img height="300px" eager class="animated fadeIn slower white--text align-end" :src="gallery.imageUrl">
       <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
      <div class="pa-1" style="background-color: rgba(0,0,0,0.4);" v-if="gallery.title != ''">
          <p class="d-flex align-start pt-5 pl-2">{{gallery.title}}</p>
      </div>
    </v-img>

  </v-card>
</v-col>
</v-row>

<v-row v-show="!GalleryData">
    <v-col cols="12" sm="12" md="4" v-for="n in 3" :key="n">
     <v-skeleton-loader class="mx-auto" type="image">
     </v-skeleton-loader>
    </v-col>
</v-row>

</div>
<div class="d-flex mt-5 justify-center align-center" v-if="GalleryData">
<v-btn large rounded text dark class="headline" style="text-transform: capitalize" @click="$router.push('/galleryoverview')">
  Show More <v-icon dark>mdi-chevron-double-right</v-icon></v-btn>
</div>
</v-container>
</section>


</div>
</template>

<script>
export default {

    name: 'gallerylist',

    computed : {
      GalleryData(){
        let data =  this.$store.getters.GetGallery.slice(0, 3);
        return data != '' ? data : null;
      },
    },
}
</script>

<style scoped>

#Gallery{
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
  color: #eee;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 2rem;
}
.image-card{
  border-radius: 10px !important;
}
</style>